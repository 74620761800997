import React from 'react';
import { Link } from 'gatsby';
import circleStripe from '../assets/images/circle-stripe.png';
import category1 from '../assets/images/categories/1.jpeg';
import category2 from '../assets/images/categories/2.jpeg';
import category3 from '../assets/images/categories/3.jpeg';
import category4 from '../assets/images/categories/4.jpeg';
import nursing from '../assets/images/categories/28(3).jpg';
import bioMed from '../assets/images/categories/bioMed.jpg'
import comm from '../assets/images/categories/commHealth.jpg';
import pb from '../assets/images/categories/3(2).jpg';
import mid from '../assets/images/categories/27(2).jpg';
import ana from '../assets/images/categories/ana.jpg'
import health from '../assets/images/categories/h3.png'
import books from '../assets/images/categories/books.jpeg';

const Schools = () => {
  return (
    <section className='course-category-three'>
      <img
        src={circleStripe}
        className='course-category-three__circle'
        alt=''
      />
      <div className='container'>
        <div className='block-title'>
          <h2 className='block-title__title'>
            Browse the schools <br />
            at our institution
          </h2>
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='course-category-three__single'>
              <img src={ana} alt='' />
              <div className='course-category-three__content'>
                <h3 className='course-category-three__title'>
                  <Link to='/schools/anaesthetic'>Anaesthetic Technicians</Link>
                </h3>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='course-category-three__single'>
              <img src={comm} alt='' />
              <div className='course-category-three__content'>
                <h3 className='course-category-three__title'>
                  <Link to='/schools/comm-health'>
                    School of Community Health{' '}
                  </Link>
                </h3>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='course-category-three__single'>
              <img src={bioMed} alt='' />
              <div className='course-category-three__content'>
                <h3 className='course-category-three__title'>
                  <Link to='/schools/biomed'>
                    Federal School of Biomedical Engineering
                  </Link>
                </h3>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='course-category-three__single'>
              <img src={books} alt='' />
              <div className='course-category-three__content'>
                <h3 className='course-category-three__title'>
                  <Link to='/schools/health-info'>
                    School of Health Information Management
                  </Link>
                </h3>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='course-category-three__single'>
              <img src={category4} alt='' />
              <div className='course-category-three__content'>
                <h3 className='course-category-three__title'>
                  <Link to='/schools/med-psy'>
                    School of Medical and Psychiatry Social Work{' '}
                  </Link>
                </h3>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='course-category-three__single'>
              <img src={mid} alt='' />
              <div className='course-category-three__content'>
                <h3 className='course-category-three__title'>
                  <Link to='/schools/midwifery'>School Of Midwifery</Link>
                </h3>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='course-category-three__single'>
              <img src={nursing} alt='' />
              <div className='course-category-three__content'>
                <h3 className='course-category-three__title'>
                  <Link to='/schools/nursing'>
                    School Of Nursing{' '}
                  </Link>
                </h3>                
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='course-category-three__single'>
              <img src={pb} alt='' />
              <div className='course-category-three__content'>
                <h3 className='course-category-three__title'>
                  <Link to='/schools/post-basic-nursing'>
                    School Of Post Basic Nursing{' '}
                  </Link>
                </h3>
                <p className='course-category-three__text'>Over 3 Courses</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Schools;
